import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardHeader, Grid } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import RestoreIcon from '@mui/icons-material/Restore';
import PaymentsIcon from '@mui/icons-material/Payments';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
const cardDetails = [
    { name: "Pay Premium (New Business / Renewal)", description: "Pay premium in order to maintain coverage and ensure the continuation of your insurance policy.", url: "https://www.pramericalife.in/PremiumPayment", icon: <PaymentsIcon style={{ width: "30px", height: "30px" }} /> ,buttonName:"Click Here To pay"},
    { name: "Update Contact Details", description: "Update Contact Details", url: "https://www.pramericalife.in/ContactDetailsUpdateLogin", icon: <PaymentsIcon style={{ width: "30px", height: "30px" }} /> ,buttonName:"Click Here To Update"},
    { name: "Customer Care/Missed Call/SMS", description: "Our Caring customer support team is here to assist you Whether you have questions. need help, or want to give feedback, we're ready to listen and provide solutions.", url: "https://www.pramericalife.in/CustomerServices/ServiceOnSMS.aspx", icon: <PhoneEnabledIcon style={{ width: "30px", height: "30px" }} />,buttonName:"Know More" },
]

interface Props {

}
const Cards: React.FC<Props> = ({ }) => {
    return (
        <Grid container spacing={1} style={{ padding: "15px" }}>
            {cardDetails.map((data: any) => {
                return (
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                        <Card variant="outlined" style={{ maxHeight: "160px", height: "160px" }} onClick={() => window.open(data.url, "_self")}>
                            <CardHeader
                                style={{ padding: "2px" }}
                                avatar={
                                    data.icon
                                }

                                title={data.name}
                            />
                            <CardContent sx={{ padding: "6px" }}>
                                <Typography variant="body2" color="text.secondary">
                                    {data.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{padding:"1px"}}>
                                <Button size="small">{data.buttonName}<KeyboardArrowRightIcon/> </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const mapStateToProps = (state: any) => ({
});

export default Cards;