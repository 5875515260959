import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
 import './index.css'
interface Props{

}
const MainCarousel:React.FC<Props> = ({
    
}) => {
  return (
    <div className="mainCaraousel">
      <Carousel
        autoPlay={true}
        showIndicators={true}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={2500}
        infiniteLoop={true}
        transitionTime={1000}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={150}
      >
                  <div
                    className="cur_pointer carouselImgContainer"
                  >
                    <img
                      className="carouselItem"
                      src="	https://www.pramericalife.in/library/images/Banners/Banner_1_Mobile.jpg
"
                      alt='carsouel'
                      onClick={() => window.open('https://www.pramericalife.in/AboutUs/WhoWeAre', "_self")}
                    />
                  </div>

                  <div
                    className="cur_pointer carouselImgContainer"
                  >
                    <img
                      className="carouselItem"
                      src="https://www.pramericalife.in/library/images/Banners/Banner_2_Mobile.jpg"
                      alt='carsouel'
                      onClick={() => window.open('https://www.pramericalife.in/LifeInsuranceProductS/Pramerica-Life-Guaranteed-Return-on-Wealth', "_self")}
                    />
                  </div>

                  <div
                    className="cur_pointer carouselImgContainer"
                  >
                    <img
                      className="carouselItem"
                      src="	https://www.pramericalife.in/library/images/Banners/Banner_3_Mobile.jpg"
                      alt='carsouel'
                      onClick={() => window.open('https://www.pramericalife.in/LifeInsuranceProductN/Pramerica-Life-Rakshak-Smart', "_self")}
                    />
                  </div>
               
               
          
      </Carousel>
    </div>
  );
};
export default MainCarousel;