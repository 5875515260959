import { Grid, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import LogoPage from '../LogoPage';
import MainCarousel from './Carousel/Carousel';
import Cards from './Cards';
import './Home.css'
import Footers from '../Footer';

interface Props{
    setPage:any;
}

const Home:React.FC <Props> = ({
    setPage
}) => {
   
    return (
        <main >
            <LogoPage />
            <Typography variant="h5" gutterBottom className='typo'>
                Welcome to Pramerica Life
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} lg={12} xl={12}>
                    <MainCarousel />
                </Grid>
                <Grid item md={12} sm={12} lg={12} xl={12}>
                    <Cards setPage={setPage}/>
                </Grid>
                <Grid item md={12} sm={12} lg={12} xl={12}>
                    <Footers />
                </Grid>
            </Grid>

        </main>
    )
}
const mapStateToProps = (state:any) => ({

})
export default Home