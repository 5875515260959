import { Button, CardHeader, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import LogoPage from '../LogoPage';
import Cards from './Cards'
import './index.css'
import Footers from '../Footer';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface Props {
    setPage:any;
}
const SelfService:React.FC <Props> = ({
    setPage,
}) => {
    return (
        <main >
            <LogoPage />
           <Button 
           startIcon={<KeyboardArrowLeftIcon/>} 
           sx={{color:"black",textTransform:"lowercase",textDecoration:"underline"}}
           onClick={()=>setPage("Home")}
           >
            back
           </Button>
            <Typography variant="h6" gutterBottom className='typo'>
                Self Service
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} lg={12} xl={12}>
                    <Cards />
                </Grid>
                {/* <Grid item md={12} sm={12} lg={12} xl={12}>
                    <Footers />
                </Grid> */}
            </Grid>

        </main>
    )
}
export default SelfService
