import React from "react";
import './index.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaYoutube,
    FaYenSign,
} from "react-icons/fa";
interface Props {

}
const Footer: React.FC<Props> = ({ }) => {
    return (
        <footer>
            <div className="row primary">
                <div className="column about">
                    <h6>BEWARE OF SPURIOUS / FRAUD PHONE CALLS : <span>IRDAI is not involved in activities like selling insurance policies announcing bonus or investment of premiums. Public receiving such phone calls are requested to lodge a police complaint </span> </h6>
                </div>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Link
                        target="_blank"
                        to="https://www.facebook.com/PramericaLifeInsurance/"
                    >
                        <FacebookIcon sx={{ color: "#ffffff" }} />
                    </Link>
                    <Link
                        target="_blank"
                        to="https://twitter.com/PramericaLife"
                    >
                        <TwitterIcon sx={{ color: "#ffffff" }} />
                    </Link>
                 
                </Box>
                <br />
            </div>
        </footer>
    )

}
const mapStateToProps = (state: any) => ({
});

export default Footer;