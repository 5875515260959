import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InventoryIcon from '@mui/icons-material/Inventory';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import QuizIcon from '@mui/icons-material/Quiz';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
const cardDetails = [
    { name: "About Pramerica Life", description: "Learn more about Pramerica Life Insurance.", url: "https://www.pramericalife.in/AboutUs", icon: <InfoIcon style={{ width: "40px", height: "40px",color:"#001F45" }} /> },
    { name: "Self Service", description: "Self - Manage your Policy.", url: "self", icon: <DesignServicesIcon style={{ width: "40px", height: "40px",color:"#001F45" }} /> },
    { name: "Our Products", description: "Life insurance solutions exclusively for Defence personnel.", url: "https://www.pramericalife.in/Downloads/Download", icon: <InventoryIcon style={{ width: "40px", height: "40px",color:"#001F45" }} /> },
    { name: "WhatsApp", description: "Check your policy Details on WhatsApp.", url: "https://wa.me/9289207070?text=Hi", icon: <WhatsAppIcon style={{ width: "40px", height: "40px",color:"#001F45" }} /> },
   
]

interface Props {
    setPage: any;
}
const Cards: React.FC<Props> = ({ setPage }) => {
    return (
        <Grid container spacing={1} style={{ padding: "15px" }}>
            {cardDetails.map((data: any,index:any) => {
                return (
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6} key={index}>
                        <Card
                            variant="outlined"
                            style={{ maxHeight: "250px", height: "250px" }}
                            onClick={() =>{ data.url==="self"?setPage("Self"):window.open(data.url, "_self")}}
                        >
                            <CardContent>
                                {data.icon}
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px", maxHeight: "45px", height: "45px" }} component="div">
                                    {data.name}
                                </Typography>
                                <Typography variant="body2" style={{ maxHeight: "80px", height: "80px" }}>
                                    {data.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <KeyboardArrowRightIcon style={{ border: "1px solid black", borderRadius: "50%", width: "30px", height: "30px" }} />
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const mapStateToProps = (state: any) => ({
});

export default Cards;